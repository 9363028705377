import { useParams } from 'react-router-dom';
import { Section, SectionHead } from '../components/Section';
import { AnimatePresence, motion } from 'framer-motion';
import { TaskTable } from '../components/TaskSummary';
import { Select } from '../components/Select';
import { Breadcrumbs } from '../components/Breadcrumbs';
import { Button } from '../components/Button';

// fake data
import filterButtonData from '../data/filterButtonData';
import { useProperty, useProperties, useSegments, exportSurveyData } from '../data/api';
import { useEffect, useState } from 'react';

const SurveyTPI = () => {
  const { survey_id } = useParams();
  // const surveys = getSurveys() as SurveyInterface[];
  const { properties: surveys, isLoading: surveysLoading } = useProperties();
  const { segments, isLoading: segmentsLoading } = useSegments(survey_id);
  const dateFilters = filterButtonData[2].options
  const [ filterDate, setFilterDate ] = useState(
    dateFilters.find(df=>df.value == sessionStorage.dateFilter)?.value || dateFilters[1].value
  );
  const [ filterSegment, setFilterSegment ] = useState('');
  const { tasks, isLoading: tasksLoading } = useProperty(survey_id, filterDate, filterSegment);

  const selectedSurvey = surveys.find((s) => s.id.toString() === survey_id);

  const handleExport = async () => {
    try {
      const blob = await exportSurveyData(survey_id);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `survey-export-${survey_id}.csv`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    } catch (error) {
      console.error('Export failed:', error);
    }
  };

  useEffect(() => {
    if(filterDate)
      sessionStorage.dateFilter = filterDate;
  }, [filterDate])

  useEffect(() => {
    document.title = selectedSurvey?.title + ' - Intentful';
  }, [selectedSurvey]);

  if (!selectedSurvey) return null;

  const totalCount = tasks.reduce((previous, current) => {
    return previous + current.responseCount;
  }, 0);

  const crumb = [
    {
      route: '/survey/' + selectedSurvey.id,
      title: selectedSurvey.title
    }
  ];

  return (
    <AnimatePresence mode="wait">
      <motion.section
        className="survey-dashboard"
        key="survey"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
        transition={{ duration: 0.3, type: 'tween' }}>
        <div className="container flow flow-2xl">
          <Breadcrumbs crumbs={crumb} />
          <Section> 
            <SectionHead
              title={selectedSurvey.title}
              intro="Track and filter the tasks you created for in-depth insights.">
              <p className="text-right flow flow-3xs">
                <span className="sh-300-md mono-grey">Total responses</span>
                <br />
                <span className="h-500-md">{totalCount.toLocaleString()}</span>
              </p>
            </SectionHead>
            <div className="flex-row-2xs">
              {/* <Select label="Channels" options={filterButtonData[0].options} /> */}
              {segments?.length && (
                <Select
                  label="People"
                  options={segments}
                  onValueChange={setFilterSegment}
                />
              )}

              <div className="flex-end">
                <Select
                  label="Date"
                  options={dateFilters}
                  onValueChange={setFilterDate}
                  value={filterDate}
                />

              </div>
            </div>

            <TaskTable tasks={tasks} loading={tasksLoading} />
            <span className="flex-row flex-justify-end">
            <Button 
                  onClick={handleExport}
                  color="secondary"
          size="medium"
                >
                  
                  Export all data as CSV
                </Button>
                </span>
          </Section>
        </div>
      </motion.section>
    </AnimatePresence>
  );
};

export { SurveyTPI };
